// src/components/ProdutosVendidos.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

function ProdutosVendidos() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/produtos_vendidos`)
      .then(response => {
        const sortedData = response.data.produtos.sort((a, b) => b.quantidade - a.quantidade);
        setData(sortedData);
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);

  return (
    <Card sx={{ backgroundColor: 'background.paper', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography color="textSecondary" variant="h6" gutterBottom align="center">
          Produtos Vendidos
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          {data.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data}>
                <XAxis dataKey="produto" stroke="#757575" />
                <YAxis stroke="#757575" />
                <Tooltip cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }} />
                <Bar dataKey="quantidade" fill="#1A73E8" radius={[5, 5, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Typography variant="body1" color="textSecondary" align="center">
              Carregando dados ou nenhum produto vendido.
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default ProdutosVendidos;
